import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`What is a Campaign?`}</h2>
    <p>{`A campaign is a planned sequence of activities and messages aimed at achieving a specific goal, such as promoting a product, raising awareness, or engaging with an audience.`}</p>
    <p>{`CoreMedia Campaigns is a cloud-native SaaS application for CoreMedia Content Cloud-Service that allows content managers to create, schedule, launch, and manage their marketing campaigns across their websites. The integration with the CoreMedia system is based on APIs and plugins that grant easy integration to CoreMedia customers. `}</p>
    <h3>{`Which campaign types exist?`}</h3>
    <ol>
      <li parentName="ol">{`An `}<em parentName="li">{`onsite campaign`}</em>{` is a marketing strategy implemented directly on a company's website to engage visitors and convert them into leads or customers. This type of campaign leverages various onsite elements to capture visitor interest, encourage interaction, and drive desired actions. `}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`There are 3 different types:`}</p>
    </blockquote>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`Campaigns: a campaign is a targeted engagement strategy displayed directly on a website or platform. These campaigns are designed to enhance user experience and drive specific actions while the visitor is actively engaging with the site.`}</li>
        <li parentName="ul">{`Landing Pages: also know as static page, lead capture page or destination page is used for capture leads, paid media and direct sales.
The main advantages are: focused measuring, increase leads, focused optimization, can be client or CoreMedia.`}</li>
        <li parentName="ul">{`A/B Testing: After each campaign implementation it's important to start the optimization phase! A/B testing solution is helpful, because we fine-tune the actions together with the client, testing different components and different combinations and evaluating which ones bring the best results: copy, colors, image, position, CTA... with focus on the results.`}</li>
      </ul>
    </blockquote>
    <h4>{`Widgets`}</h4>
    <p>{`A widget is a pre-configured block that has a specific and defined goal. Widgets are often embedded in websites or desktop environments and can perform a variety of tasks. They can be used as active or passive elements and can be customized.`}</p>
    <p>{`Onsite Campaign are able to use different widgets such as:`}</p>
    <ul>
      <li parentName="ul">{`Click2call`}</li>
      <li parentName="ul">{`Smart window`}</li>
      <li parentName="ul">{`Live chat`}</li>
      <li parentName="ul">{`Video call`}</li>
      <li parentName="ul">{`Chatbot`}</li>
      <li parentName="ul">{`Video broadcast`}</li>
      <li parentName="ul">{`Messaging app`}</li>
      <li parentName="ul">{`Forms`}</li>
      <li parentName="ul">{`Banners`}</li>
      <li parentName="ul">{`Re-targeting cookies`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <br />2. An _offline campaign_ is a marketing strategy that utilizes traditional, non-digital methods to reach and engage an audience. These campaigns are conducted through physical channels and do not rely on Internet connectivity for delivery. Here we have several solutions that allow us to connect in all scenarios: email marketing, SMS, SMS with callback, voice outbound, call center outbound, push notifications. 
    <h3>{`What is a smart window?`}</h3>
    <p>{`A Smart Window is an interactive tool designed to enhance campaigns by providing customers with effortless options to engage via Click-to-Call or Live Chat or Video Call, or Chatbot. `}</p>
    <h4>{`What are the key benefits?`}</h4>
    <ul>
      <li parentName="ul">{`Enhanced Customer Engagement `}</li>
      <li parentName="ul">{`Improved Campaign Management `}</li>
      <li parentName="ul">{`Real-time product recommendations; `}</li>
      <li parentName="ul">{`All-in-one shopping experience, from product recommendation to checkout; `}</li>
      <li parentName="ul">{`Personalized experience and higher customer satisfaction. `}</li>
    </ul>
    <h2>{`CoreMedia Conversion Models`}</h2>
    <h3>{`1. What is an Attribution Model ?`}</h3>
    <p>{`A model that waits for conversions and retrospectively analyzes how to distribute the influence of conversions. `}</p>
    <p><strong parentName="p">{`What models are supported on the attribution model?`}</strong></p>
    <p>{`Attribution models let you choose how much credit each interaction gets for your conversions. Attribution models can give you a better understanding of how your ads perform and can help you optimize across conversion journeys. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Linear`}</strong>{`: Distributes the weight for the conversion equally across all interactions on the path `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`First Iteration`}</strong>{`: Gives full credit to the first interaction that caused the conversion. E.g.: If the user clicks on a display and search ad and converts it to the landing page, the display ad is credited.  `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Last Iteration`}</strong>{`: Gives full credit to the last interaction that caused the conversion. E.g.: Users see a business' Instagram post, visit their bio, click their listed website link, and convert. The landing page is credited because it was the ultimate point of conversion. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Based on Position (First and Last Position)`}</strong>{`: Gives 40% of weight to both the first and last interactions and corresponding keywords, with the remaining 20% spread out across the other ad iterations on the path. E.g.: If the user clicks on a display and search ad and converts to the landing page, both the display ad and landing page are credited.`}</p>
        <p parentName="li">{`And we can define the period to evaluate the interactions: `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Session`}</strong>{`: inside one session `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Days`}</strong>{`: inside X days configured `}</p>
      </li>
    </ul>
    <h3>{`2. What is a conversion based on Lead Model?​`}</h3>
    <p>{`This model differs from traditional attribution models in that it focuses on identifying the first significant interaction leading to a conversion, rather than waiting for the conversion event itself to occur and then analyzing preceding interactions. Once this initial interaction is identified, the model uses attribution algorithms to understand how the conversion value is distributed.`}</p>
    <p>{`Currently implemented for voice and chat contacts, the model provides the advantage of real-time visibility into customer engagement levels, allowing timely interventions improving the customer journey to achieve the goal. Extending this model to digital content will involve adapting its framework to include interactions across digital channels, thereby refining the attribution calculation window accordingly. ​
​
In short, this model simply defines differently the window of interactions for the calculation of the attribution model. ​
The attribution model is based on either a single visitor session or X days back from the conversion date/time. ​
The lead model, on the other hand, relies on X days forward from a specific interaction. The lead concludes at the end of these X days or upon conversion within that period.​`}</p>
    <p>{`What I need to do?​ `}</p>
    <ul>
      <li parentName="ul">{`Create the Lead Type.`}</li>
      <li parentName="ul">{`Define the number of days that lead will be opened.`}</li>
      <li parentName="ul">{`Associate what interactions are relevant to this conversion.
​`}</li>
    </ul>
    <h3>{`3. What is a conversion based on A/B Model?​`}</h3>
    <p>{`A/B testing is a method used to validate conversion hypotheses by comparing multiple versions of a website or application or content. It involves presenting different versions to separate groups of users to measure and determine the most effective in terms of conversion rates. This process helps identify which version performs better based on user interaction and behavior data.`}</p>
    <br /> A/B testing on Engagement Cloud is part of a campaign.​
    <h2>{`CoreMedia Segmentation`}</h2>
    <p>{`Segmentation is the process of dividing a broad audience into smaller, more defined groups based on shared characteristics, behaviors, or needs. This allows businesses to tailor their marketing strategies, products, and services to meet the specific preferences of each segment, driving more personalized and effective engagement.
There are some concepts that should be taken into account:`}</p>
    <ul>
      <li parentName="ul">{`Audience - An audience represents a group of visitors/contacts grouped for communication or analysis purposes.`}</li>
      <li parentName="ul">{`Audience Segments - Filtered rules on your audience's data to categorize a group of visitors/ contacts.`}</li>
    </ul>
    <p><strong parentName="p">{`Type of Segmentation`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Visitor Profile - Automatic mechanism to identify visitor profile.`}</li>
      <li parentName="ul">{`Segments - Only a Tag. Associate visitor to a segment only via API.`}</li>
      <li parentName="ul">{`Lead Scoring - Visitor has a score, based on history. Prioritize leads based on their qualification (static) by assigning points on several milestones in their journey. Attributes and weights are manually defined.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      